<template>
  <div class="container py-5">
    <p class="fw-bold">2022 美沃斯國際醫學美容大會</p>
    <p>時間：2022 07/15-17</p>
    <p>地點：杭州國際博覽中心（G20峰會會址）</p>
    <p>伊美展位：A9/B8</p>
    <p>
      2022年7月15日-7月17日，（杭州）美沃斯國際醫學美容大會完美落幕，一場行業交流與共創的盛會不僅給整個行業注入了新的活力，更展示出疫情下醫美人不屈不撓的鬥志與決心。
      <br><br>
      大會以 “熱愛，一起向未來”為主題，雲集眾多權威專家、名企展商、行業從業者，共同交流並探討行業發展趨勢與前沿熱點。新銳觀點激烈碰撞，前瞻智慧厚重激盪，為各界精英人士帶來一場學術饕餮盛宴。會議以“思變”的命題演講、“聚力”的暢談展望、“求真”的技術探討三大中心思想為主旨，為大家呈現一場集學術、技術、藝術、管理、時尚、文化於一身的綜合性產業大會，以恢弘之勢吹響了行業奮勇向前的集結號。
    </p><br>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_07_1.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
